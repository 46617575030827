<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">安知课堂</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">合作课程库</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="searchData.name"
                size="small"
                placeholder="请输入查询名称"
                clearable
              />
            </div>
            <div title="推送到精品课" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:8rem">推送到精品课:</span>
              <el-select
                v-model="searchData.option"
                placeholder="请选择"
                clearable
                size="small"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                :disabled="disabledButton"
                @click="pullCourse()"
                >拉取课程</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
            >
              <el-table-column
                label="序号"
                align="center"
                fixed
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="课程名称"
                align="center"
                prop="ksbCourseBaseName"
              />
              <el-table-column
                label="标价"
                align="center"
                prop="ksbCourseBasePrice"
              />
              <el-table-column
                label="推送到精品课"
                align="center"
                prop="ksbCourseState"
              >
                <template slot-scope="scope">{{
                  scope.row.ksbCourseState == "10" ? "否" : "是"
                }}</template>
              </el-table-column>
              <el-table-column
                label="拉取时间"
                align="center"
                prop="createTime"
              />
              <el-table-column label="操作" align="center">
                <div slot-scope="scope" class="flexcc">
                    <el-button
                      type="text"
                      style="padding: 0px 5px"
                      :disabled="scope.row.ksbCourseState == '20'"
                      size="mini"
                      @click="pushExcellentCourses(scope.row.ksbCourseId)"
                      >推送到精品课</el-button
                    >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue"; //引入表格无数据提示
import PageNum from "@/components/PageNum.vue"; //引入分页-组件
import List from "@/mixins/List"; //引入列表 - 混入
import { resetKeepAlive } from "@/utils/common"; //缓存页面
export default {
  name: "cooperativeCourse/cooperativeCourseLibrary",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      //检索数据
      searchData: {
        name: "", // 查询课程名称
        option: "", //是否推送选项
      },
      //发布系统选项
      options: [
        {
          value: "20",
          label: "是",
        },
        {
          value: "10",
          label: "否",
        },
      ],
      disabledButton:false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    //获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum,
        pageSize: this.pageSize,
      };
      if(this.searchData.name){
        params.ksbCourseBaseName=this.searchData.name;
      }
      if(this.searchData.option){
        params.ksbCourseState=this.searchData.option;
      }
      this.doFetch({
        url: "/biz/ksb/ksbCourse/ksbCourseList",
        params,
        pageNum,
      });
    },
    //拉取课程
    pullCourse() {
      this.$post("/biz/ksb/ksbCourse/pullKsbCourse", {}).then(
        (res) => {
           this.$message({
            message: '拉取课程成功',
            type: 'success'
            });
      this.disabledButton = true
            if(this.disabledButton) {
              setTimeout(() => {
                this.disabledButton = false;
                 this.getData();
              },3000)
            }
           
        }
      );  
    },
    //推送精品课
    pushExcellentCourses(CourseId){
      this.$post("/biz/ksb/ksbCourse/pushKsbMiniCourse", {ksbCourseId:CourseId}).then(
        (res) => {
           this.$message({
            message: '推送精品课成功',
            type: 'success'
            });
            this.getData(-1);
        }
      );  
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 0.675 ) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scope>
.searchBox {
  padding: 0 !important;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>
